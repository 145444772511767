import { computed } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

// import { env } from '@/libs/env'
import store from '@/store'

export default function useCreateBookingHandle() {
  // const { isDevelopment } = env

  const sourcesCanBuyAncillary = computed(() => {
    const data = ['VN1A', 'VJ', 'VU', 'AK', 'TH', '1A', 'QH']
    // if disable env
    return data
  })

  const sourcesCanBuySeat = computed(() => {
    const data = ['VN1A', 'VJ', 'VU', 'AK', 'QH']
    // if disable env
    return data
  })

  function canBuyAncillary(source, clientId, contentSource) {
    const validSource = sourcesCanBuyAncillary.value.includes(source)
    const validClientId = ['VN1A'].includes(source) ? !['INTERNAL'].includes(clientId) : true
    const validContentSource = ['1A'].includes(source) ? ['GDS'].includes(contentSource) : true
    return validSource && validClientId && validContentSource
  }

  function canBuySeat(source, clientId, contentSource) {
    const validSource = sourcesCanBuyAncillary.value.includes(source)
    const validClientId = ['VN1A'].includes(source) ? !['INTERNAL'].includes(clientId) : true
    const validContentSource = true
    return validSource && validClientId && validContentSource
  }

  const sourcesRequireVerifyPrice = ['VN1A', '1A', '1B', 'TR']

  const isHideFunction = computed(() => store.getters['app-flight-v2/getIsHideFunction'])

  function toggleHideFunction() {
    store.dispatch('app-flight-v2/toggleHideFunction')
  }

  const convertFirstName = firstName => {
    const arrName = firstName.split(' ')
    return arrName.map((item, index) => (index !== arrName.length - 1 ? item[0] : item)).join(' ')
  }

  const calculateTotalLength = (human, isConvertName = false, sources) => {
    const firstNameLength = (human.paxType === 'INFANT' && isConvertName) ? (convertFirstName(human.firstName)?.length || 0) : (human.firstName?.length || 0)
    return (human.title?.length || 0)
        + firstNameLength
        + (human.lastName?.length || 0)
        + (sources.some(s => ['1A'].includes(s)) && !['INFANT'].includes(human.paxType) ? 0 : (human.birthday?.length || 0))
  }

  const createData = (type, pax, totalLength, isOverLimit, canModify, infantPaxId = null) => ({
    type,
    pax: cloneDeep(pax),
    strNameInfant: type === 'double' ? convertFirstName(pax[0].firstName) : null,
    isOverLimit,
    canModify,
    totalLength,
    infantPaxId,
  })

  const pooledPaxCheckMaxLength = (paxs, sources) => {
    const result = []

    paxs.forEach(pax => {
      if (pax.paxType === 'INFANT' && pax.parentPaxId) {
        const adult = paxs.find(f => f.paxId === pax.parentPaxId)
        const infantArr = [pax, adult]
        const totalLength = infantArr.reduce((total, human) => total + calculateTotalLength(human, false, sources), 0)
        const totalLengthModifyINF = infantArr.reduce((total, human) => total + calculateTotalLength(human, true, sources), 0)
        const isOverLimit = sources.some(s => ['1A'].includes(s)) ? (totalLength > 57) : (totalLength > 47)
        const canModify = sources.some(s => ['1A'].includes(s))
          ? false // (isOverLimit && totalLengthModifyINF < 57) -> 1A no-edit name INF
          : (isOverLimit && totalLengthModifyINF < 47)
        const data = createData('double', infantArr, totalLength, isOverLimit, canModify, pax.paxId)
        result.push(data)
      } else {
        const totalLength = calculateTotalLength(pax, false, sources)
        const isOverLimit = sources.some(s => ['1A'].includes(s)) ? (totalLength > 57) : (totalLength > 54)
        const data = createData('one', pax, totalLength, isOverLimit, false, null)
        result.push(data)
      }
    })

    return result
  }

  const updatePassengerCountToLocalStorage = paxLists => {
    const storageData = JSON.parse(localStorage.getItem('searchFlight')) || {};
    ['ADULT', 'CHILD', 'INFANT'].forEach(paxType => {
      storageData[paxType.toLowerCase()] = paxLists.filter(p => p.paxType === paxType).length
    })
    localStorage.setItem('searchFlight', JSON.stringify(storageData))
  }

  return {
    sourcesCanBuyAncillary,
    sourcesCanBuySeat,
    sourcesRequireVerifyPrice,
    convertFirstName,
    pooledPaxCheckMaxLength,
    updatePassengerCountToLocalStorage,

    isHideFunction,
    toggleHideFunction,

    canBuyAncillary,
    canBuySeat,
  }
}
